import React from "react"
import BokaFormular from "../components/boka-formular"
import Layout from "../components/layout"
import Link from "../components/link"

export default function BokaPage() {
  return (
    <Layout>
      <div className="bg-primary/30 min-h-screen">
        <div className="container max-w-4xl mx-auto py-8 md:py-16 px-4 md:px-8">
          <div>
            <p>
              Om du vill boka en tid eller se/ändra din bokning klickar du{" "}
              <Link to="https://boka.frenda.se/344804" className="underline">
                här
              </Link>
              . Har du övriga frågor kan du använda formuläret nedan.
            </p>
          </div>
          <BokaFormular />
        </div>
      </div>
    </Layout>
  )
}
